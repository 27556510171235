<template>
  <div class="clients">
    <span id="loading" v-if="this.loading == true">
      <Loading />
    </span>
    <h1>Clients</h1>
    <div class="row" id="filters">
      <div class="col">
        <div class="input-group input-group-sm mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="inputGroup-sizing-sm">Nome</span>
          </div>
          <input type="text" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" v-model="nameSearch" @change="getClients()" />
        </div>
      </div>
      <div class="col">
        <div class="input-group input-group-sm mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="inputGroup-sizing-sm">Email</span>
          </div>
          <input type="text" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" v-model="emailSearch" @change="getClients()" />
        </div>
      </div>
      <div class="col">
        <div class="input-group input-group-sm mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="inputGroup-sizing-sm">Telemóvel</span>
          </div>
          <input type="text" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" v-model="phoneSearch" @change="getClients()" />
        </div>
      </div>
    </div>
    <div class="table">
      <b-table striped hover :items="clients" :fields="fields" :per-page="perPage" :current-page="currentPage" thead-class="text-white" small>
        <template #cell(id)="data">
          <b-link @click="routeToProduct(data.item.id)">{{ data.item.id }}</b-link>
        </template>
        <template #cell(email)="data">
          <a v-for="email in data.item.email" :key="email">
            <a>
              {{ email }}
            </a>
          </a>
        </template>
        <template #cell(phone)="data">
          <a v-for="phone in data.item.phone" :key="phone">
            <a>
              {{ phone }}
            </a>
          </a>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Loading from '@/components/Loading.vue';
export default {
  components: {
    Loading,
  },
  data: function () {
    return {
      perPage: 20,
      currentPage: 1,
      rows: 1,
      nameSearch: '',
      emailSearch: '',
      phoneSearch: '',
      loading: false,
      clients: [],
      fields: [
        {
          key: 'id',
          label: 'ID',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'name',
          label: 'Nome',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'email',
          label: 'Email',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'phone',
          label: 'Telemóvel',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
      ],
    };
  },
  methods: {
    async getClients() {
      if (this.nameSearch == '' && this.emailSearch == '' && this.phoneSearch == '' && (this.selectedBrand == '' || this.selectedBrand == null)) {
        this.clients = [];
      } else {
        console.log(this.nameSearch, this.emailSearch, this.phoneSearch);
        await this.$store.dispatch('searchClients', {
          name: this.nameSearch,
          email: this.emailSearch,
          phone: this.phoneSearch,
        });
        this.clients = this.getSearchClients.clients;
        this.perPage = this.getSearchClients.length;
      }
    },
    routeToProduct(clientID) {
      this.$router.push(`clients/${clientID}`);
    },
  },
  computed: {
    ...mapGetters(['getSearchClients']),
  },
  created() {
    /* NA CRIAÇÃO DA PÁGINA CHAMA A FUNÇÃO QUE RETORNA TODOS OS PRODUTOS */
    /*  this.getAllProducts(); */
  },
};
</script>

<style>
.clients {
  text-align: center;
}
.table {
  font-size: 12px;
  margin-top: 30px;
}

.searchbar {
  position: absolute;
  right: 0px;
  width: 200px;
  margin: 10px 20px 0px 0px;
}
#filters {
  padding: 0px 100px 0px 100px;
}
.customPage {
  background-color: tomato;
}

h2 {
  margin-top: 20px;
}

.btn {
  margin: 0px 5px 0px 5px;
}
</style>
